<template>
  <div>
    
    <SchemaTable
      ref="schematableref"
      :schema="schema"
      :model="dataModel"
      :queryConf="queryConf"
      :defaultFormData="defaultFormData"
      :canCreate="false"
      :canEdit="false"
      :canDelete="false"
      :hasQuery="false"
      :hasAction="false"
    >
      <!-- <template #actionBtns="{ row }">
        <el-link class="action-link" @click="detail(row)"> 查看 </el-link>
      </template> -->
    </SchemaTable>
  </div>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./procedureLog.schema.json";
import { uris, attachId } from "./../../api/uri";
import moment from "moment";

import _ from "lodash";
import axios from "axios";

export default {
  components: {
    SchemaTable,
  },
  props: {
    processLogId: String,
  },
  created() {

  },
  data() {
    return {
      deviceInfo: {},
      defaultFormData: {},
      queryConf: { closePrefix: true },
      dialogVisible: false,
      schema,
      dataModel: new DataModel({
        getListApi: `${uris.procedureLog}`,
        getListMap(item) {
          item.createdTime = +new Date(item.created_at);
          item.timeStart = +new Date(item.timeStart);
          item.timeEnd = +new Date(item.timeEnd);
          item.timeDialogStart = item.timeDialogStart ? +new Date(item.timeDialogStart) : '';
          item.timeDialogEnd = item.timeDialogEnd ? +new Date(item.timeDialogEnd) : '';
          item.result = item.result ? '成功' : '失败';
          return item;
        },
        getMap(res) {
          return res;
        },
        query: {
          pageNumber: 1,
          pageSize: 10,
          processLogId: this.processLogId,
        },
        axiosConfig: {
          timeout: 10000,
        },
      }),
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    detail(row) {
      console.log(row);
      axios.get(`${uris.processLog}/${row.workId}?deviceId=${row.deviceId}`).then((res) => {
        if (res.data.code !== 0) {
          this.$message({
            type: "error",
            message: res.data.message,
          });
          return;
        }
      });
    },
  },
};
</script>

<style scoped>
.text {
  font-size: 14px;
  color: #999;
}
.item {
  margin-bottom: 10px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
</style>