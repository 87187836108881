<template>
  <div>
    
    <SchemaTable
      ref="schematableref"
      :schema="schema"
      :model="dataModel"
      :queryConf="queryConf"
      :defaultFormData="defaultFormData"
      :canCreate="false"
      :canEdit="false"
      :canDelete="false"
      :hasQuery="false"
    >
      <template #actionBtns="{ row }">
        <el-link type="primary" class="action-link" @click="detail(row)"> 查看 </el-link>
      </template>
    </SchemaTable>
  </div>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./processLog.schema.json";
import { uris, attachId } from "./../../api/uri";
import moment from "moment";

import _ from "lodash";
import axios from "axios";
const statusMap = {
  1: "产测执行进行中",
  2: "产测执行失败",
  3: "产测执行成功",
};
export default {
  components: {
    SchemaTable,
  },
  props: {
    deviceId: String,
    workId: String,
  },
  created() {
    this.defaultFormData.deviceId = this.deviceId;
    this.defaultFormData.workId = this.workId;
    axios.get(`${uris.processLog}/${this.workId}?deviceId=${this.deviceId}`).then((res) => {
        if (res.data.code !== 0) {
          this.$message({
            type: "error",
            message: res.data.message,
          });
          return;
        }
        this.deviceInfo = res.data.result;
    });
  },
  data() {
    return {
      deviceInfo: {},

      defaultFormData: {},
      queryConf: { closePrefix: true },
      dialogVisible: false,
      schema,
      dataModel: new DataModel({
        getListApi: `${uris.processLog}/${this.workId}`,
        getListMap(item) {
          item.createdTime = +new Date(item.created_at);
          item.success = item.success ? '成功': '失败';
          item.processName = item.process ? item.process.name : '';
          item.workPlaceName = item.workPlace ? item.workPlace.name : '';
          item.productLineName = item.productLine ? item.productLine.name : '';
          item.userName = item.user ? item.user.username : '';
          item.status = item.device ? item.device.status : '';
          item.workflowName = item.workflow ? item.workflow.name : '';
          return item;
        },
        getMap(res) {
          return res;
        },
        query: {
          pageNumber: 1,
          pageSize: 10,
          deviceId: this.deviceId,
        },
        axiosConfig: {
          timeout: 10000,
        },
      }),
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    detail(row) {
      this.$emit('procedureLogEvent', row);
    },
  },
};
</script>

<style scoped>
.text {
  font-size: 14px;
  color: #999;
}
.item {
  margin-bottom: 10px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
</style>