<template>
  <div>
    <el-dialog
      title="查看设备详情"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
    <el-row :gutter="12">
  <el-col :span="12">
      <el-card class="box-card" style="margin-top: 20px">
        <div slot="header" class="clearfix">
          <span>基本信息</span>
        </div>
        <div class="text item">
          生产码：{{deviceInfo.id}}
        </div>
        <div class="text item">
          当前状态：{{deviceInfo.status}}
        </div>
        <div class="text item">
          设备SN：{{deviceInfo.sn}}
        </div>
        <div class="text item">
          设备GWEUI：{{deviceInfo.gweui || '-'}}
        </div>
         <div class="text item">
          设备MAC：{{deviceInfo.macStr || '-'}}
        </div>
         <div class="text item">
          所属订单：{{deviceInfo.orderName}}
        </div>
         <div class="text item">
          所属工厂：{{deviceInfo.factoryName || '-'}}
        </div>
         <div class="text item">
          创建时间：{{deviceInfo.created_at}}
        </div>
      </el-card>
       </el-col>
  <el-col :span="12">
      
      </el-col>
</el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose()">关 闭</el-button>
      </span>
    </el-dialog>
    <el-row :gutter="20" class="page-box task-list-page">
      <el-col>
        <Breadcrumb
          :home="false"
          :breadcrumbs="[
            {
              label: '设备',
              to: '/device',
            },
          ]"
        />
      </el-col>
      <el-col>
         <el-card class="grid-card" shadow="never">
          <el-tabs
            v-model="editableTabsValue"
            type="card"
            @tab-remove="removeTab"
          >
            <el-tab-pane
              v-for="(item, index) in editableTabs"
              :key="item.name"
              :closable="index != 0"
              :label="item.title"
              :name="item.name"
            >
              <span v-if="item.type == 0">
               <SchemaTable
                  ref="schematableref"
                  :schema="schema"
                  :model="dataModel"
                  :filters="filters"
                  :queryConf="queryConf"
                  :hasReset="hasReset"
                  :canCreate="canCreate"
                  :canDelete="false"
                >
                  <template #actionBtns="{ row }">
                    <el-link type="primary" class="action-link" @click="detail(row)"> 查看 </el-link>
                  </template>
                </SchemaTable>
              </span>
              <span v-if="item.type == 1">
                <worklife @processLogEvent="processLogEv" :deviceId="deviceId"></worklife>
              </span>
              <span v-if="item.type == 2">
                <process-log @procedureLogEvent="procedureLogEv" :workId="workId" :deviceId="deviceId"></process-log>
              </span>
               <span v-if="item.type == 3">
                <procedure-log :processLogId="processLogId"></procedure-log>
              </span>
            </el-tab-pane>
          </el-tabs>
        </el-card>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./device.schema.json";
import { uris, attachId } from "./../../api/uri";
import Breadcrumb from "enn-breadcrumb";
import axios from "axios";
import Worklife from "./../worklife/index.vue";
import ProcessLog from "./../processLog/index.vue";
import ProcedureLog from "./../procedureLog/index.vue";

export default {
  components: {
    SchemaTable,
    Breadcrumb,
    Worklife,
    ProcessLog,
    ProcedureLog,
  },
  data() {
    return {
      search: "",
      filters: ["sn"],
      schema,
      hasReset: true,
      deviceInfo: {},
      workLife: {},
     editableTabsValue: "1",
      editableTabs: [
        {
          title: "设备",
          name: "1",
          id: 0,
          type: 0,
        },
      ],
      tabIndex: 1,
      canCreate: false,
      dialogVisible: false,
      queryConf: { closePrefix: true },
      dataModel: new DataModel({
        getListApi: `${uris.device}`,
        getListMap(item) {
          item.createdTime = +new Date(item.created_at);
          item.sn = item.sn ? item.sn.sn : "";
          item.gweui = item.gweuis.length ? item.gweuis[0].gweui : "";
          item.orderName = item.order ? item.order.name : "";
          item.factoryName = item.order
            ? item.order.factory
              ? item.order.factory.name
              : ""
            : "";
          // item.macs.forEach(mac => {
          //   item.macStr += mac.mac + '\n';
          // });
          return item;
        },
        getApi: `${uris.device}${attachId}`,
        getMap(res) {
          return res;
        },
        query: { pageNumber: 1, pageSize: 10 },
        axiosConfig: {
          timeout: 10000,
        },
      }),
    };
  },
  methods: {
    async handleClose() {
      this.dialogVisible = false;
    },
    procedureLogEv(row) {
      this.processLogId = row.id;
      let newTabName = ++this.tabIndex + "";
      const name = '工序执行（' + this.processLogId + '）';
      for (const tab of this.editableTabs) {
        if(tab.title === name) {
          this.editableTabsValue = tab.name;
          return;
        }
      }
      this.editableTabs.push({
        title: name,
        name: newTabName,
        type: 3,
        id: this.tabIndex,
      });
      this.editableTabsValue = newTabName;
    },
    processLogEv(row) {
      this.workId = row.workId;
      let newTabName = ++this.tabIndex + "";
      const name = '步骤执行（' + this.workId + '）';
      for (const tab of this.editableTabs) {
        if(tab.title === name) {
          this.editableTabsValue = tab.name;
          return;
        }
      }
      this.editableTabs.push({
        title: name,
        name: newTabName,
        type: 2,
        id: this.tabIndex,
      });
      this.editableTabsValue = newTabName;
    },
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
    },
    detail(row) {
      this.deviceId = row.id;
      let newTabName = ++this.tabIndex + "";
      let name = '';
      if (row.sn && typeof row.sn === 'string') {
        name = row.sn;
      } else {
        name = row.sn ? row.sn.sn : this.deviceId;
      }
      for (const tab of this.editableTabs) {
        if(tab.title === name) {
          
          this.editableTabsValue = tab.name;
          return;
        }
      }
      this.editableTabs.push({
        title: name,
        name: newTabName,
        type: 1,
        id: this.tabIndex,
      });
      this.editableTabsValue = newTabName;
    },
  },
};
</script>

<style scoped>
 .text {
    font-size: 14px;
    color: #999;
 }
  .item {
    margin-bottom: 10px;
    
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
</style>