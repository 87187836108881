<template>
  <div>
    <el-dialog
      title="步骤执行详情"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关闭</el-button>
      </span>
    </el-dialog>

    <div>
      <el-card class="box-card" style="margin-bottom: 10px;">
        <div slot="header" class="clearfix">
          <span>基本信息</span>
        </div>
        <div>
          <el-row :gutter="12">
            <el-col :span="12">
              <div class="text item">生产码：{{ deviceInfo.id }}</div>
              <div class="text item">当前状态：{{ deviceInfo.status }}</div>
              <div class="text item">设备SN：{{ deviceInfo.sn }}</div>
              <div class="text item">
                设备GWEUI：{{ deviceInfo.gweui || "-" }}
              </div>
            </el-col>
            <el-col :span="12">
              <div class="text item">
                设备MAC：{{ deviceInfo.macStr || "-" }}
              </div>
              <div class="text item">所属订单：{{ deviceInfo.orderName }}</div>
              <div class="text item">
                所属工厂：{{ deviceInfo.factoryName || "-" }}
              </div>
              <div class="text item">创建时间：{{ deviceInfo.created_at }}</div>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>

    <SchemaTable
      ref="schematableref"
      :schema="schema"
      :model="dataModel"
      :queryConf="queryConf"
      :defaultFormData="defaultFormData"
      :canCreate="false"
      :canEdit="false"
      :canDelete="false"
      :hasQuery="false"
    >
      <template #actionBtns="{ row }">
        <el-link type="primary" class="action-link" @click="detail(row)"> 查看 </el-link>
      </template>
    </SchemaTable>
  </div>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./worklife.schema.json";
import { uris, attachId } from "./../../api/uri";
import moment from "moment";
import _ from "lodash";
import axios from "axios";
const statusMap = {
  1: "产测执行进行中",
  2: "产测执行成功",
  3: "产测执行失败",

};
export default {
  components: {
    SchemaTable,
   
  },
  props: {
    deviceId: String,
  },
  created() {
    this.defaultFormData.deviceId = this.deviceId;
    axios.get(`${uris.device}/${this.deviceId}`).then((res) => {
      if (res.data.code !== 0) {
        this.$message({
          type: "error",
          message: res.data.message,
        });
        return;
      }
      this.deviceInfo = res.data.result;
      Object.assign(this.deviceInfo, {
        sn:
          (this.deviceInfo.sn ? this.deviceInfo.sn.sn : "") +
          (this.deviceInfo.sn ?.deleted_at ? "（已删除）" : ""),
        gweui:
          (this.deviceInfo.gweuis.length
            ? this.deviceInfo.gweuis[0].gweui
            : "") + (this.deviceInfo.gweuis[0]?.deleted_at ? "（已删除）" : ""),
        orderName:
          (this.deviceInfo.order ? this.deviceInfo.order.name : "") +
          (this.deviceInfo.order.deleted_at ? "（已删除）" : ""),
        factoryName: this.deviceInfo.order
          ? this.deviceInfo.order.factory.name
          : "",
        created_at: moment(this.deviceInfo.created_at).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      });
      this.deviceInfo.macs.forEach((mac) => {
        this.deviceInfo.macStr += mac.mac + "\n";
      });

    });
  },
  data() {
    return {
      deviceInfo: {},

      defaultFormData: {},
      queryConf: { closePrefix: true },
      dialogVisible: false,
      schema,
      dataModel: new DataModel({
        getListApi: `${uris.workflowLife}`,
        getListMap(item) {
          item.createdTime = +new Date(item.created_at);
          item.status = statusMap[item.status];
          return item;
        },
        getMap(res) {
          return res;
        },
        query: {
          pageNumber: 1,
          pageSize: 10,
          deviceId: this.deviceId,
        },
        axiosConfig: {
          timeout: 10000,
        },
      }),
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    detail(row) {
      this.$emit('processLogEvent', row);
    },
  },
};
</script>

<style scoped>
.text {
  font-size: 14px;
  color: #999;
}
.item {
  margin-bottom: 10px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
</style>